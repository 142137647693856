import PhysicalLocation from "./physicalLocation"
import CropYear from "./cropYear";
import FuturesContract from "./futuresContract";
import Commodity from "./commodity";
import { formatUTCDate } from "common/formatters";

export default class DeliveryPeriod {
    id: string = "";
    name: string;
    erpName: string;
    contract: FuturesContract = null as FuturesContract;
    commodity: Commodity = null as Commodity;
    cropYear: CropYear = null as CropYear;
    start: Date = null as Date;
    end: Date = null as Date;
    startString?: string = "";
    endString?: string = "";
    htaFee: number = 0;
    premium: number = null;
    lastUpdatedDateUtc: Date = null as Date;
    lastUpdatedBy: string = "";
    bids: Bid[] = [] as Bid[];
    visibility: DeliveryPeriodVisibility = DeliveryPeriodVisibility.Public;
    isSpot: boolean;
}

export function getDeliveryPeriodDisplayName(dp: DeliveryPeriod): string {
    //for delayedPrice contracts deliveryPeriod wont't be set but while retrieving them from DB deliveryPeriod.commodity will be set     
    //with all other deliveryPeriod properties as null
    if(dp?.id == null) return "";
    return dp.name ?? `${formatUTCDate(dp.start)}-${formatUTCDate(dp.end)}`;
  }

export class Bid {
    id: string = "";
    location: PhysicalLocation = null as PhysicalLocation;
    basis: number = 0;
    cash: number = 0;
    isBasisLocked?: boolean = false;
}

export class Region {
    regionId: string = null;
    locationIds: string[] = [] as string[];
    isSelected: boolean = false;

    constructor(regionId: string, isSelected: boolean){
        this.regionId = regionId;
        this.isSelected = isSelected;
    }
}

export class CommodityRegions {
    commodityId: number = null;
    regions: Region[] = [] as Region[];

    constructor(commodityId: number, regions: Region[]){
        this.commodityId = commodityId;
        this.regions = regions;
    }
}

export enum DeliveryPeriodVisibility {
    Public = 1,
    InternalOnly = 2,
    BidEditorOnly = 3
}

export function getDeliveryPeriodVisibilityText(visibility: DeliveryPeriodVisibility): string {
    switch (visibility) {
        case DeliveryPeriodVisibility.Public:
            return "Public";
        case DeliveryPeriodVisibility.InternalOnly:
            return "Internal Only";
        case DeliveryPeriodVisibility.BidEditorOnly:
            return "Bid Editor Only";
        default:
            throw new Error("Unknown visibility");
    }
}